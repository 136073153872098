<template>
  <layout width="540">
    <template slot="header">
      Create objects from devices
    </template>

    <template #no-offset>
      <v-tabs v-model="tab">
        <v-tabs-slider />
        <pre />
        <v-tab
          v-for="(item, index) in items"
          :key="item.name"
          :disabled="
            (!data.general.type || !data.general.objectType) && index !== 0
          "
        >
          {{ item.name }}
        </v-tab>
      </v-tabs>
      <v-divider />
      <v-tabs-items v-model="tab" style="overflow-y: auto">
        <v-tab-item v-for="item in items" :key="item.name" class="px-4">
          <component
            :is="item.component"
            :data="data"
            :tab="tab"
            @update-form="setData"
          />
        </v-tab-item>
      </v-tabs-items>
    </template>
    <template slot="footer">
      <v-spacer />
      <v-btn text color="text-primary" @click="close">
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        depressed
        :loading="false"
        :disabled="!data.general.type || !data.general.objectType"
        @click="submit"
      >
        Create
      </v-btn>
    </template>
  </layout>
</template>

<script>
import { reactive, ref, toRefs } from '@vue/composition-api';
import { useMutation } from '@vue/apollo-composable';
import { usePopup } from '@/compositions/popup';

import { useAuth } from '@/modules/auth/api';

import Layout from '@/components/popup/PopupLayoutDefault';
import GeneralTab from '@/modules/objects/ui/objects-from-devices/general';

import linkedObjectScheme from '@/modules/linked-objects/api/linked-object.graphql';
import { objectService } from '@/modules/objects/api';

export default {
  name: 'ObjectCreate',
  components: {
    LocationTab: () =>
      import('@/modules/objects/ui/objects-from-devices/location'),
    ItemsTab: () => import('@/modules/objects/ui/objects-from-devices/items'),
    StatesTab: () => import('@/modules/objects/ui/objects-from-devices/states'),
    GeneralTab,
    Layout
  },
  props: {
    centerMap: { type: Array, default: () => [] }
  },
  setup(props, { emit }) {
    const popup = usePopup();

    const state = reactive({
      data: {
        centerMap: props.centerMap,
        general: {},
        states: [],
        location: [],
        monitoringItems: [],
        geoItems: []
      }
    });

    const { mutate: createControlExecution } = useMutation(
      linkedObjectScheme.createControlExecution
    );

    const { getUserProfileId } = useAuth();

    const tab = ref(null);
    const items = [
      { name: 'general', component: 'GeneralTab' },
      { name: 'states', component: 'StatesTab' },
      { name: 'items', component: 'ItemsTab' },
      { name: 'location', component: 'LocationTab' }
    ];

    const close = () => {
      emit('close');
    };

    const checkTemplateMacros = (str) => {
      return str.includes('{:d}') ? str : `${str} {:d}`
    }

    const preSubmit = title => {
      popup.openConfirm({
        component: () => import('@/components/popup/PopupConfirmAction.vue'),
        props: {
          title,
          onConfirm: async () => {
            try {
              const profileId = await getUserProfileId();
              state.data.general.objectNameTemplate = checkTemplateMacros(state.data.general?.objectNameTemplate || '')

              await createControlExecution({
                input: {
                  objectId: profileId,
                  name: 'AddMonitorObjects',
                  params: {
                    JWT: localStorage.getItem('authToken'),
                    PARAMETERS: JSON.stringify(state.data)
                  }
                }
              });

              setTimeout(close);
            } catch (e) {
              throw new Error(e);
            }
          }
        }
      });
    };

    const setData = async obj => {
      if (obj.key === 'items') {
        state.data.monitoringItems = obj.data.monitoringItems;
        state.data.geoItems = obj.data.geoItems;
      } else {
        state.data[obj.key] = obj.data;
      }
    };

    const submit = async () => {
      console.info(JSON.stringify(state.data), 'payload');
      const statusMap = {
        'Enabled': true,
        'Disabled': false
      }
      const totalCount = await objectService.getCountDevices(
        state.data.general.objectType,
        state.data.general.objectNameContains,
        statusMap[state.data.general.status]
      );
      preSubmit(`Create ${totalCount} object(s)?`);
    };

    return {
      ...toRefs(state),
      items,
      tab,
      close,
      submit,
      preSubmit,
      setData
    };
  }
};
</script>
